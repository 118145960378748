import { ToolbarDropdownMenu } from "@wordpress/components"
import { justifyCenter, justifyRight, justifyLeft, justifySpaceBetween, chevronTop, alignCenter, alignBottom, alignStretch  } from "@wordpress/icons"

export default function PositionSettings(props) {
    const pprops = props.parentProps
    const icons = {
        start : justifyLeft,
        center: justifyCenter,
        end: justifyRight,
        justify: justifySpaceBetween
    }
    const vicons = {
        top: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false"><path d="M9 20h6V9H9v11zM4 4v1.5h16V4H4z"></path></svg>,
        center: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false"><path d="M20 11h-5V4H9v7H4v1.5h5V20h6v-7.5h5z"></path></svg>,
        bottom: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false"><path d="M15 4H9v11h6V4zM4 18.5V20h16v-1.5H4z"></path></svg>,
        stretch: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" aria-hidden="true" focusable="false"><path d="M4 4L20 4L20 5.5L4 5.5L4 4ZM10 7L14 7L14 17L10 17L10 7ZM20 18.5L4 18.5L4 20L20 20L20 18.5Z"></path></svg>
    }

    return (
        <>
            <ToolbarDropdownMenu
                icon={ icons[pprops.attributes.align] }
                label="Buttons alignment"
                controls={ [
                    {
                        title: 'Left',
                        icon: icons['start'],
                        onClick: () => pprops.setAttributes({align:"start"}),
                    },
                    {
                        title: 'Center',
                        icon: icons['center'],
                        onClick: () => pprops.setAttributes({align:"center"}),
                    },
                    {
                        title: 'Right',
                        icon: icons['end'],
                        onClick: () => pprops.setAttributes({align:"end"}),
                    },
                    {
                        title: 'Justify',
                        icon: icons['justify'],
                        onClick: () => pprops.setAttributes({align:"justify"}),
                    },
                ] }
            />
            <ToolbarDropdownMenu 
                icon={ vicons[pprops.attributes.valign] }
                label="Buttons vertical alignment"
                controls={ [
                    {
                        title: 'Top',
                        icon: vicons['top'],
                        onClick: () => pprops.setAttributes({valign:"top"}),
                    },
                    {
                        title: 'Center',
                        icon: vicons['center'],
                        onClick: () => pprops.setAttributes({valign:"center"}),
                    },
                    {
                        title: 'Bottom',
                        icon: vicons['bottom'],
                        onClick: () => pprops.setAttributes({valign:"bottom"}),
                    },
                    {
                        title: 'Stretch',
                        icon: vicons['stretch'],
                        onClick: () => pprops.setAttributes({valign:"stretch"}),
                    },
                ]}
            />
        </>
    )
}
  