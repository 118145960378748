import { useBlockProps, InnerBlocks , BlockControls, Inserter, InspectorControls } from "@wordpress/block-editor";
import  PositionSettings from "./settings/PositionSettings.js"
import  ButtonsSettings from "./settings/ButtonsSettings.js"
import "./index.scss";
import metadata from './block.json';
import deprecated from "./deprecated.js";

function EditComponent( props ) {
  //define blocks class and styles
  const blockProps = useBlockProps({
    className: props.attributes.blockClass
  })

  //components-dropdown-menu
  return (
      <div {...blockProps } >
        <BlockControls>
          <PositionSettings parentProps={props} />
          <Inserter rootClientId={props.clientId} />
        </BlockControls>
        <InspectorControls>
          <ButtonsSettings parent_props={props}/>
        </InspectorControls>
        <div className={`${props.attributes.align} valign-${props.attributes.valign}`} style={{gap:props.attributes.gap}}>
          <InnerBlocks orientation="horizontal" allowedBlocks={ ['jims/button'] } />
        </div>
      </div>
  )
}

function SaveComponent( {attributes} ){
  const blockProps = useBlockProps.save()
  blockProps.className += " jims-block-buttons-front"
  blockProps.className += " "+attributes.align
  blockProps.className += " valign-"+attributes.valign   

  return (
      <div { ...blockProps } style={{gap:attributes.gap}} >
        <InnerBlocks.Content/>
      </div>
  )
}
if(wp.blocks) {
    wp.blocks.registerBlockType(metadata.name, {
        ...metadata,
        icon: {
            src:
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path
                        d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-8z"/>
                    <path
                        d="m7.823 2.823-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                </svg>
        },
        edit: EditComponent,
        save: SaveComponent,
        deprecated
    });
}