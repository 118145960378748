import "./index.scss"
import { useBlockProps } from '@wordpress/block-editor'
import {  InnerBlocks  } from '@wordpress/block-editor'
import metadata from './block.json'

const v1 ={
    attributes: metadata.attributes,
    
    save( {attributes} ){
        const blockProps = useBlockProps.save()
        blockProps.className += " jims-block-buttons-front"
        blockProps.className += " "+attributes.align
        blockProps.className += " valign-"+attributes.valign      

        return (
            <div { ...blockProps } style={{gap:attributes.gap}}>
                <InnerBlocks.Content/>
            </div>
        )
   }
}

export default [ v1 ]