import {  PanelBody, PanelRow } from "@wordpress/components"
import { __experimentalUnitControl as UnitControl } from '@wordpress/components';


export default function ButtonsSettings( props ) {
    const blockClass = props.className
    const pprops = props.parent_props
    
    return (
      <PanelBody title="Buttons Settings" initialOpen={true}>
        {/**
         * Gap settings
         */}
        <PanelRow>
            <UnitControl label="Gap" onChange={ (newgap)=>pprops.setAttributes({gap:newgap}) } value={ pprops.attributes.gap } />   
        </PanelRow>
      </PanelBody>
    )
}